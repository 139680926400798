import React, { useState, useEffect, useMemo, useContext } from 'react';
import { format, parse, isBefore, isSameDay } from 'date-fns';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import LoaderComponent from '../../Shared/Loader';
import { AuthContext } from '../../Providers/UserProvider';

const VacationPage = () => {
    const { techId, techsRef } = useContext(AuthContext);
    const [selectedVacationDays, setSelectedVacationDays] = useState<Array<Date>>([]);
    const [showLoader, setShowLoader] = useState(false);

    function updateVacation(dates: Date[]) {
        const finalDays = dates?.map((date) => {
            return format(date, 'yyyy-MM-dd');
        });
        techsRef.doc(techId).update({
            vacation: finalDays,
        });
    }

    useEffect(() => {
        setShowLoader(true);
        techsRef
            .doc(techId)
            .get()
            .then((snapshot) => {
                const { vacation: vacationDays = [] } = snapshot.data() as Partial<{ vacation?: Array<any> }>;
                setSelectedVacationDays(
                    vacationDays.map((vacationDay) => parse(vacationDay, 'yyyy-MM-dd', new Date())),
                );
                setShowLoader(false);
            });
    }, [techId, techsRef]);

    function isDateBeforeToday(date: Date) {
        return isBefore(date, new Date());
    }

    // Active days are the number of days that someone has marked for vacation that are not in the past
    // filter out the days in the past and count
    const activeDays: number = useMemo(
        () => selectedVacationDays.filter((vacationDate) => !isDateBeforeToday(vacationDate)).length,
        [selectedVacationDays],
    );

    const handleDayClick = (day: Date, { selected, modifiers = {} }: any) => {
        if (!modifiers.disabled) {
            // Toggle: if this day is already selected, filter it out (this is no longer a vacation day)
            // otherwise we should add it since toggling = reversing
            const updatedSelectedDays = selected
                ? selectedVacationDays.filter((dateItem) => !isSameDay(dateItem, day))
                : selectedVacationDays.concat(day);
            setSelectedVacationDays([...updatedSelectedDays]);
            updateVacation(updatedSelectedDays);
        }
    };

    return (
        <div>
            <LoaderComponent showLoader={showLoader} />
            <div className="bg-white-600 py-4 text-center shadow-lg">
                <div className="my-auto pl-2.5 pr-2.5">
                    <p className="text-2xl text-gray-800 font-semibold">Vacation Days</p>
                    <p className="text-lg text-gray-800">{activeDays} active vacation days</p>
                </div>
            </div>
            <div className="p-8">
                <DayPicker
                    disabledDays={{
                        before: new Date(),
                    }}
                    className="w-full shadow-lg lg:shadow-none"
                    selectedDays={selectedVacationDays}
                    onDayClick={handleDayClick}
                />
            </div>
        </div>
    );
};
export default VacationPage;
