import Swal from 'sweetalert2';

export const getConfirmation = async (
    title: string,
    text: string,
    { cancelButtonText, confirmButtonText, showCancelButton } = {
        cancelButtonText: '',
        confirmButtonText: '',
        showCancelButton: true,
    },
) => {
    const confirmed = await Swal.fire({
        title,
        text,
        allowEscapeKey: true,
        showCancelButton,
        showConfirmButton: true,
        ...(cancelButtonText && { cancelButtonText }),
        ...(confirmButtonText && { confirmButtonText }),
    });
    return confirmed && confirmed.value;
};

export const showError = (err: string | Error, title = 'Error with request') => {
    if (typeof err === 'string') {
        return (error) => showError(error, err);
    }
    return Swal.fire({
        title,
        text: err.message,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
    });
};
