import React, { FunctionComponent, useState } from 'react';
import { auth } from '../../../firebase';

interface Props {
    onClose: () => void;
}

const ForgotPasswordModal: FunctionComponent<Props> = (Props) => {
    const [email, setEmail] = useState('');
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const [error, setError] = useState(null);
    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;
        if (name === 'userEmail') {
            setEmail(value);
        }
    };
    const sendResetEmail = (event) => {
        event.preventDefault();
        auth.sendPasswordResetEmail(email)
            .then(() => {
                setEmailHasBeenSent(true);
                setTimeout(() => {
                    setEmailHasBeenSent(false);
                }, 3000);
                setError(null);
            })
            .catch((errors) => {
                setError(errors.message);
            });
    };
    return (
        <div>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-2xl font-semibold">Forgot Password?</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            {emailHasBeenSent && (
                                <div className="py-3 bg-green-400 w-full text-white text-center mb-3">
                                    An email has been sent to you!
                                </div>
                            )}
                            {error !== null && (
                                <div className="py-3 bg-primaryTheme w-full text-white text-center mb-3">{error}</div>
                            )}
                            <form>
                                <input
                                    type="email"
                                    name="userEmail"
                                    id="userEmail"
                                    value={email}
                                    placeholder="Input your email"
                                    onChange={onChangeHandler}
                                    className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                />
                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                            <button
                                className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                onClick={Props.onClose}
                            >
                                Close
                            </button>
                            <button
                                className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                onClick={(event) => {
                                    sendResetEmail(event);
                                }}
                            >
                                Send Reset Link
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </div>
    );
};
export default ForgotPasswordModal;
