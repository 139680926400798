import React, { useContext, useState, useEffect, useMemo } from 'react';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { auth, db } from '../../firebase';
import { AuthContext } from '../../Providers/UserProvider';
import skillsList from '../../Constants/skillsList';

const SettingsPage = () => {
    const { tech, user }: any = useContext(AuthContext);
    const [techRegion, setTechRegion] = useState('');
    const [availableRegions, setAvailableRegions] = useState<Array<{ name: string; center: any; id: string }>>([]);
    const [newPhone, setNewPhone] = useState<''>(tech.phone);

    // needs to be the available regions for the tech
    useEffect(() => {
        return db.collection(`/regions`).onSnapshot((regionSnapshot) => {
            setAvailableRegions(regionSnapshot.docs.map((doc) => ({ ...(doc.data() as any), id: doc.id })));
        });
    }, [setAvailableRegions]);

    const isValidPhone = useMemo(() => {
        const phoneUtil = PhoneNumberUtil.getInstance();
        let parsedPhone;
        try {
            parsedPhone = phoneUtil.parse(newPhone, 'US');
        } catch (e) {
            return false;
        }
        return phoneUtil.isValidNumber(parsedPhone);
    }, [newPhone]);

    const getFormattedPhone = (phone) => {
        const phoneUtil = PhoneNumberUtil.getInstance();
        return phoneUtil.format(phoneUtil.parse(phone, 'US'), PhoneNumberFormat.NATIONAL);
    };

    const isPhoneChanged = useMemo(() => {
        if (!isValidPhone) {
            return true;
        }
        return getFormattedPhone(newPhone) !== getFormattedPhone(tech.phone);
    }, [newPhone, tech.phone, isValidPhone]);

    const formatNewPhone = () => {
        if (!isValidPhone) {
            return;
        }
        setNewPhone(getFormattedPhone(newPhone));
    };

    const regionId = useMemo(() => (user ? user?.claims?.regionId : null), [user]);

    const updatePhone = async () => {
        await db
            .collection(`/regions/${user.claims.regionId}/techs`)
            .doc(user.uid)
            .update({ phone: newPhone?.toString() });
    };

    useEffect(
        () =>
            db
                .collection('/regions')
                .doc(regionId)
                .onSnapshot((regionDoc) => {
                    const data = regionDoc.data();
                    setTechRegion(data?.name);
                }),
        [regionId],
    );

    // needs to change user claim regionId
    const onChangeHandler = (setFunc: (value: any) => void) => (evt) => setFunc(evt.currentTarget.value);

    return (
        <div className="overflow-y-scroll mb-16">
            <div className="bg-white-600 py-4 text-center shadow-lg">
                <div className="my-auto">
                    <p className="text-2xl text-gray-800 font-semibold">Settings</p>
                    <p className="text-lg text-gray-800">Welcome {tech.name}</p>
                </div>
            </div>
            <div className="flex flex-col p-8">
                <h2 className="text-2xl underline"> Account details </h2>
                <div className="mt-4 text-base text-gray-600 text-left">
                    <p className="my-1">Name: {tech.name}</p>
                    <p className="my-1">Email: {tech.email}</p>
                    <p className="my-1">Phone: {tech.phone}</p>
                </div>
                <div className="mt-4">
                    <p className="mr-3 text-gray-800 mb-3">You are currently set to: {techRegion}</p>
                    <select
                        id="regionSelect"
                        className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                        required
                        value={techRegion}
                        onChange={onChangeHandler(setTechRegion)}
                    >
                        <option className="placeholder-gray-600">Change region</option>
                        {availableRegions.map((region) => (
                            <option key={region.id} value={region.id}>
                                {region.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mt-4">
                    <p className="text-gray-800 ">Change Phone Number</p>
                    <div className="flex">
                        <input
                            id="changePhone"
                            type="text"
                            value={newPhone ?? ''}
                            onBlur={formatNewPhone}
                            className="block border border-gray-400 rounded text-right focus:outline-none focus:ring-2 focus:ring-gray-200 mr-3 px-2"
                            onChange={onChangeHandler(setNewPhone)}
                        />
                        <button
                            type="button"
                            className={`text-white py-2 px-4  rounded ${
                                !isValidPhone || !isPhoneChanged
                                    ? 'opacity-50 bg-gray-400 cursor-auto'
                                    : 'bg-red-400 hover:bg-red-700'
                            }`}
                            disabled={!isValidPhone || !isPhoneChanged}
                            onClick={updatePhone}
                        >
                            Update
                        </button>
                    </div>
                    {!isValidPhone && <small className="text-red-500">Phone number is invalid</small>}
                </div>
            </div>

            <div className="flex flex-col p-8">
                <h2 className="text-2xl underline mb-2"> Your skills </h2>
                {skillsList.map((skill) => (
                    <div key={skill} className="inline">
                        <span>
                            {skill}
                            <input
                                checked={(tech.skills ?? []).includes(skill)}
                                onChange={(evt) => {
                                    db.doc(`/regions/${regionId}/techs/${tech.id}`).update({
                                        ...tech,
                                        skills: (tech.skills ?? [])
                                            .filter((s) => s !== skill)
                                            .concat(evt.target.checked ? [skill] : []),
                                    });
                                }}
                                className="ml-3"
                                type="checkbox"
                            />
                        </span>
                    </div>
                ))}
            </div>

            <div className="flex flex-col p-8">
                <h2 className="text-2xl underline"> Logout </h2>

                <div className="mt-4 text-base text-gray-600 text-left">
                    <p className="my-1">
                        SolutionWorks is a resource management app designed for you. If you have any questions or issues
                        please email us at:{' '}
                        <a className="text-blue-400" href="mailto:example@solutionworks.com">
                            example@solutionworks.com
                        </a>
                    </p>
                </div>
                <div className="text-center mt-12">
                    <button
                        type="button"
                        className="bg-primaryTheme bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded w-72"
                        onClick={() => {
                            auth.signOut();
                        }}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};
export default SettingsPage;
