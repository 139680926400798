import React from 'react';
import PropTypes from 'prop-types';
import BarLoader from 'react-spinners/BarLoader';

const override = 'display: block;margin: 0 auto;';

const LoaderComponent = ({ showLoader }) => {
    return (
        <>
            {showLoader && (
                <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-95 z-50">
                    <div className="text-dark sweet-loading mt-32">
                        <p className="text-5xl font-medium text-center">TechRepair</p>
                        <p className="text-lg font-medium text-center mt-4">Technician Scheduling Made Easy</p>
                        <p className="mt-12">
                            <BarLoader color="black" loading css={override} size={75} />
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default LoaderComponent;

LoaderComponent.propTypes = {
    showLoader: PropTypes.bool,
};

LoaderComponent.defaultProps = {
    showLoader: false,
};
