import React, { useCallback, FunctionComponent } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import CarRejected from '../../../../Assets/Images/detailsCarRejected.png';
import { IDefaultJob } from '../../../../Shared/Interfaces';
import newDate from '../../../../utils/newDate';

interface Props {
    rejectedJob: IDefaultJob;
    onClose: () => void;
}

const RejectedJobModal: FunctionComponent<Props> = (Props) => {
    const closeModal = useCallback(() => {
        Props.onClose();
    }, [Props]);

    return (
        <>
            <div className="fixed w-full h-full top-0 left-0 flex z-50 ">
                <div
                    className="fixed w-full overflow-y-scroll bg-details-background bg-cover pb-12"
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{ height: '100%' }}
                >
                    <div className="fixed justify-start p-2 border-b border-solid border-gray-300 bg-gray-100 w-full">
                        <button
                            className="text-blue-500 background-transparent font-bold text-sm outline-none focus:outline-none"
                            type="button"
                            onClick={closeModal}
                        >
                            <FontAwesomeIcon className="text-base mr-1" icon={faChevronLeft} />
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="pt-14 container mx-auto h-auto text-left pb-4">
                        <div className="flex flex-col items-center">
                            <div className="uppercase text-xl font-medium">
                                {Props.rejectedJob.status} {Props.rejectedJob.type} Job
                            </div>
                            <div className="mt-6 text-3xl">
                                {Props.rejectedJob.vehicle?.year} {Props.rejectedJob.vehicle?.make}{' '}
                                {Props.rejectedJob.vehicle?.model}
                            </div>
                            <div className="mt-6 md:max-w-lg max-w-sm">
                                <img src={CarRejected} alt="Tech Scheduler Car" />
                            </div>
                            <div className="mt-4 text-base text-gray-600 text-center">
                                <p className="my-1">Insurance: {Props.rejectedJob.vehicle?.insurance}</p>
                                <p className="my-1">Contact: {Props.rejectedJob.contact}</p>
                                <p className="my-1">
                                    Date:{' '}
                                    {Props.rejectedJob.date &&
                                        format(newDate(Props.rejectedJob.date), 'MM/dd/yyyy h:mm a')}
                                </p>
                                <p className="my-1">Status: {Props.rejectedJob.dropped}</p>
                            </div>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    disabled
                                    className="bg-gray-200 hover:bg-gray-200 text-white font-bold py-3 px-4 rounded w-72"
                                >
                                    Job Rejected
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RejectedJobModal;
