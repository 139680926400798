import React from 'react';
import './App.css';
import PWAPrompt from 'react-ios-pwa-prompt';
import Router from './Shared/Router';
import { UserProvider } from './Providers/UserProvider';

function App() {
    return (
        <>
            <UserProvider>
                <Router />
                <PWAPrompt
                    timesToShow={3}
                    permanentlyHideOnDismiss={false}
                    copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen and while offline. Please open on Safari."
                />
            </UserProvider>
        </>
    );
}

export default App;
