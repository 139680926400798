/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format, parse } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { NavLink } from 'react-router-dom';
import { TechProject } from '../ProjectDetailPage';

const ProjectResults = ({ techProjects }: { techProjects: TechProject[] }) => {
    const formatDate = (dateStr) => {
        return format(parse(dateStr, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
    };

    return (
        <div className="rounded-sm shadow-md p-4">
            {isEmpty(techProjects) && (
                <div className="text-center p-2">
                    <p className="text-lg font-medium">No projects to show</p>
                </div>
            )}
            {!isEmpty(techProjects) && (
                <div className="sm:divide-y sm:divide-gray-300">
                    <div className="hidden sm:grid grid-cols-4 text-center pt-2 pb-2 bg-blue-500 text-white">
                        <div className="col-span-1">
                            <p className="font-medium text-lg">Title</p>
                        </div>
                        <div className="col-span-1">
                            <p className="font-medium text-lg">Client</p>
                        </div>
                        <div className="col-span-1">
                            <p className="font-medium text-lg">Start Date</p>
                        </div>
                        <div className="col-span-1">
                            <p className="font-medium text-lg">End Date</p>
                        </div>
                    </div>
                    {techProjects.map((techProject) => (
                        <div className="grid grid-cols-4 text-center sm:hover:bg-gray-100" key={techProject.id}>
                            <div className="col-span-4 sm:col-span-1">
                                <div className="grid grid-cols-4">
                                    <div className="col-span-1 sm:hidden flex flex-col bg-blue-500 rounded-tl-lg">
                                        <div className="my-auto">
                                            <p className="text-white">Title</p>
                                        </div>
                                    </div>
                                    <div className="col-span-3 sm:col-span-4 p-2 text-left pl-6 sm:pl-2 sm:text-center border-gray-300 border-t sm:border-0">
                                        <NavLink to={`/app/projects/${techProject.projectId}/${techProject.id}`}>
                                            <p className="text-blue-500 hover:text-blue-700">
                                                {techProject.projectTitle}
                                            </p>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-4 sm:col-span-1">
                                <div className="grid grid-cols-4">
                                    <div className="col-span-1 sm:hidden flex flex-col bg-blue-500">
                                        <div className="my-auto">
                                            <p className="text-white">Client</p>
                                        </div>
                                    </div>
                                    <div className="col-span-3 sm:col-span-4 p-2 text-left pl-6 sm:pl-2 sm:text-center border-gray-300 border-t sm:border-0">
                                        <p>{techProject.projectClient}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-4 sm:col-span-1">
                                <div className="grid grid-cols-4">
                                    <div className="col-span-1 sm:hidden flex flex-col bg-blue-500">
                                        <div className="my-auto">
                                            <p className="text-white">Start Date</p>
                                        </div>
                                    </div>
                                    <div className="col-span-3 sm:col-span-4 p-2 text-left pl-6 sm:pl-2 sm:text-center border-gray-300 border-t sm:border-0">
                                        <p>{formatDate(techProject.startDate)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-4 mb-2 sm:mb-0 sm:col-span-1">
                                <div className="grid grid-cols-4">
                                    <div className="col-span-1 sm:hidden flex flex-col bg-blue-500 rounded-bl-lg">
                                        <div className="my-auto">
                                            <p className="text-white">End Date</p>
                                        </div>
                                    </div>
                                    <div className="col-span-3 sm:col-span-4 p-2 text-left pl-6 sm:pl-2 sm:text-center border-gray-300 border-t border-b sm:border-0">
                                        <p>{formatDate(techProject.endDate)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

ProjectResults.propTypes = {
    techProjects: PropTypes.array.isRequired,
};

export default ProjectResults;
