/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { parse, format } from 'date-fns';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { db, functions } from '../../firebase';
import { AuthContext } from '../../Providers/UserProvider';

export interface TechProject {
    createdAt: number;
    endDate: string;
    hoursLogged: number;
    projectId: string;
    startDate: string;
    techId: string;
    projectTitle: string;
    projectClient: string;
    id: string;
}

interface ProjectDetails {
    address: {
        city: string;
        state: string;
        street: string;
        zip: string;
    };
    clientName: string;
    createdAt: number;
    description: string;
    endDate: string;
    geofenceTimeCard: boolean;
    hidden: boolean;
    hoursLogged: number;
    lat: number;
    lng: number;
    maximumTechs: number;
    numberOfVehicles: string;
    skills: [];
    startDate: string;
    title: string;
    id: string;
}

const ProjectDetailPage = ({ match }: { match: any }) => {
    const { tech, user }: any = useContext(AuthContext);
    const projectId = useMemo(() => (match.params ? match.params.projectId : null), [match]);
    const techProjectId = useMemo(() => (match.params ? match.params.techProjectId : null), [match]);
    const [techProject, setTechProject] = useState({} as TechProject);
    const [projectDetails, setProjectDetails] = useState({} as ProjectDetails);
    const [isClockedIn, setIsClockedIn] = useState(false);
    const [isClocking, setIsClocking] = useState(false);

    const dateFormat = 'yyyy-MM-dd';

    const hoursToFormattedTime = (hoursLogged: number) => {
        const minutes = Math.round((hoursLogged % 1) * 60);
        const hours = Math.floor(hoursLogged);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    };

    useEffect(() => {
        return db
            .collection(`/regions/${user.claims.regionId}/timeRecords`)
            .where('techProjectId', '==', techProjectId)
            .where('timestampOut', '==', null)
            .onSnapshot((snap) => {
                setIsClockedIn(snap.docs.length > 0);
            });
    }, [techProjectId, user]);

    useEffect(() => {
        return db
            .collection(`/regions/${user.claims.regionId}/projects`)
            .doc(`/${projectId}`)
            .onSnapshot((doc) => {
                setProjectDetails({ ...doc.data(), id: doc.id } as ProjectDetails);
            });
    }, [user, projectId]);

    useEffect(() => {
        return db
            .collection(`/regions/${user.claims.regionId}/techProjects`)
            .doc(techProjectId)
            .onSnapshot((doc) => {
                setTechProject(doc.data() as TechProject);
            });
    }, [user, techProjectId]);

    const clockIn = async () => {
        await functions
            .httpsCallable('clockInToProject')({
                techId: tech.id,
                regionId: user?.claims?.regionId,
                projectId,
                techProjectId,
            })
            .then(() => {
                setIsClockedIn(true);
                setIsClocking(false);
            });
    };

    const clockOut = async () => {
        await functions
            .httpsCallable('clockOutOfProject')({
                techId: tech.id,
                regionId: user?.claims?.regionId,
                projectId,
                techProjectId,
            })
            .then(() => {
                setIsClockedIn(false);
                setIsClocking(false);
            });
    };

    const parseDate = (date: string, formatStr: string): Date => {
        return parse(date, formatStr, new Date());
    };

    const formatDate = (date: string): string => {
        return format(parseDate(date, dateFormat), 'MM/dd/yyyy');
    };

    const isTechOnProjectToday = useMemo(() => {
        if (!techProject) {
            return false;
        }
        const today = new Date();
        return (
            today >= parseDate(techProject.startDate, dateFormat) && today <= parseDate(techProject.endDate, dateFormat)
        );
    }, [techProject]);

    return (
        <div className="max-w-3/4 mx-auto">
            {isTechOnProjectToday && (
                <div className="p-4 shadow-md rounded-lg my-4 grid grid-cols-6">
                    <div className="col-span-6 xs:col-span-2 sm:col-span-3  align-middle h-min my-auto text-center py-2">
                        <p className="text-lg">
                            <span className="font-medium">Time logged by {tech.name}:</span>{' '}
                            {hoursToFormattedTime(techProject.hoursLogged)}
                        </p>
                    </div>
                    <div className="col-span-6 xs:col-span-4 sm:col-span-3 align-middle">
                        <div className="flex flex-row justify-center h-full items-center">
                            <div>
                                <button
                                    type="button"
                                    className={`mx-2 px-4 py-2 rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50 ${
                                        isClockedIn || isClocking
                                            ? 'bg-gray-300 hover:bg-gray-300 text-gray-50 cursor-default'
                                            : 'bg-green-500 hover:bg-green-700'
                                    }`}
                                    disabled={isClockedIn || isClocking}
                                    onClick={() => {
                                        setIsClocking(true);
                                        clockIn();
                                    }}
                                >
                                    Clock in
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className={`mx-2 px-4 py-2 rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-opacity-50 ${
                                        !isClockedIn || isClocking
                                            ? 'bg-gray-300 hover:bg-gray-300 text-gray-50 cursor-default'
                                            : 'bg-red-500 hover:bg-red-700'
                                    }`}
                                    disabled={!isClockedIn || isClocking}
                                    onClick={() => {
                                        setIsClocking(true);
                                        clockOut();
                                    }}
                                >
                                    Clock out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isEmpty(projectDetails) && (
                <div className="p-4 shadow-md rounded-lg my-4">
                    <div className="text-center border-gray-100 border-b-2 pb-2">
                        <p className="text-xl font-medium">{projectDetails.title}</p>
                        <p>{projectDetails.description}</p>
                    </div>

                    <div className="pt-2">
                        <table className="mx-auto">
                            <tbody className="divide-y divide-gray-200">
                                <tr>
                                    <td className="p-4">
                                        <p className="font-medium">Address</p>
                                    </td>
                                    <td className="p-4">
                                        <p>{projectDetails.address.street}</p>
                                        <p>
                                            {projectDetails.address.city}, {projectDetails.address.state}{' '}
                                            {projectDetails.address.zip}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-4">
                                        <p className="font-medium">Client</p>
                                    </td>

                                    <td className="p-4">
                                        <p>{projectDetails.clientName}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-4">
                                        <p className="font-medium">Dates</p>
                                    </td>
                                    <td className="p-4">
                                        <p>
                                            {formatDate(projectDetails.startDate)} -{' '}
                                            {formatDate(projectDetails.endDate)}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-4">
                                        <p className="font-medium">Total time logged on project</p>
                                    </td>
                                    <td className="p-4">
                                        <p>{hoursToFormattedTime(projectDetails.hoursLogged)}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

ProjectDetailPage.propTypes = {
    match: PropTypes.object.isRequired,
};

export default ProjectDetailPage;
